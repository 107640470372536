import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Loadingicon } from "../../../../AppIcons";
import BreadCrumbs from "../../../common/BreadCrumbs";
import { useAuth } from "../../../../Context/AuthContext";
import { Formik } from "formik";
import CustomSelect from "../../../common/customselect";
import { getForumSubject, getForumTags, getForumTopic, postAForumQuestion } from "../../../../api/forum";
import { capitalizeLabel, EDITOR_KEY, ERROR_SOMETHING_WENT_WRONG, TINY_EDITOR_KEY } from "../../../../utils/utils";
import { useSearch } from "../../../../Context/SearchContext";
// import { Editor } from "@tinymce/tinymce-react";

import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Table, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import { registerLicense } from '@syncfusion/ej2-base';
import CustomSelectAsync from "../../../common/customselect/asyncselect";
registerLicense(EDITOR_KEY);

function PostAQuestion() {
    const moderateEditorRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    
    const [catId, setCatId] = useState(null);
    const [sCatId, setSCatId] = useState();
    const [isCategoryLoading, setIsCategoryLoading] = useState(false);
    const [isSubCategoryLoading, setIsSubCategoryLoading] = useState(false);
    const { globalToken, user, setIsEmailVerifyCover } = useAuth();
    const [categoryFormatted, setCategoryFormatted] = useState([]);
    const [subCategoryFormatted, setSubCategoryFormatted] = useState([]);
    const [defaultSCat, setDefaultSCat] = useState({});
    const [sfValue, setSfValue] = useState(null);

    const [tagId, setTagId] = useState([]);        
    const [tagFormatted, setTagFormatted] = useState([]);   
    const [isTagLoading, setIsTagLoading] = useState(false);


    const toolbarSettings = {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|',
            'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
            'SourceCode', 'CreateTable', 'FullScreen', '|', 'Undo', 'Redo']
    };

    const quickToolbarSettings = {
        image: ['Replace', 'Align', 'Caption', 'Remove', 'InsertLink', 'OpenImageLink', '-', 'EditImageLink', 'RemoveImageLink', 'Display', 'AltText', 'Dimension'],
        link: ['Open', 'Edit', 'UnLink']
    };



    const DisplayingErrorMessagesSchema = Yup.object().shape({
        question: Yup.string().required("Question is required"),
        cat_id: Yup.string().required("Category is required"),
        scat_id: Yup.string().required("Sub Category is required"),
        tag: Yup.array().min(1, "Choose at least one tag").required("Tags are required")
        
    });
    const DisplayingErrorMessagesSchemaSec = Yup.object().shape({
        question: Yup.string().required("Question is required"),
        cat_id: Yup.string().required("Category is required"),
        tag: Yup.array().min(1, "Choose at least one tag").required("Tags are required")
    });


    const navigate = useNavigate();
    useEffect(() => {
        handleFetchAllForumTags();
        handleFetchAllForumSubject(0);
    }, [])



    const handleFetchAllForumSubject = async (pageNo) => {
        const resAllSubjects = await getForumSubject({ pageNo });
        if (resAllSubjects?.success) {
            const forumCat = resAllSubjects?.data?.category;
            if (forumCat?.length) {
                const cData = forumCat?.map(option => ({
                    value: option.id,
                    label: option.name
                })) || [];
                setCategoryFormatted(cData);
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
    }


    // fetch all topic by categry
    const handleFetchAllForumTopic = async (pageNo, forumCatId) => {
        const resallTopics = await getForumTopic({ pageNo, forumCatId });
        if (resallTopics?.success) {
            const forumTopic = resallTopics?.data?.Sub_category;
            if (forumTopic?.length) {
                const sCData = forumTopic?.map(option => ({
                    value: option.id,
                    label: option.name
                })) || [];
                setSubCategoryFormatted(sCData);
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
    }

    const formData = [
        {
            id: 'cat_id',
            title: 'Category',
            type: 'text',
            name: 'cat_id',
            placeholder: 'Pick one from the list',
            required: true,
            editable: true,
            isCategory: true
        }, {
            id: 'scat_id',
            title: 'Sub Category',
            type: 'text',
            name: 'scat_id',
            placeholder: '',
            required: true,
            editable: true,
            isSCategory: true

        },
        {
            id: 'tag',
            title: 'Tag',
            type: 'text',
            name: 'tag',
            placeholder: 'Pick from the list',
            required: true,
            editable: true,
            isTag: true
        },
        {
            id: 'question',
            title: 'Question',
            type: 'text',
            name: 'question',
            placeholder: 'Put your question here',
            required: true,
            editable: true,
            width: true
        }
    ]


    const handleFetchAllForumTags = async () => { 
                const resAllForumTags = await getForumTags();
                if (resAllForumTags?.success) {
                    const forumTags = resAllForumTags?.data;
                    if (forumTags?.length) {
                        const cData = forumTags?.map(option => ({
                            value: option.id,
                            label: capitalizeLabel(option.name)
                        })) || [];
                        setTagFormatted(cData); 
                    }
                } else {
                    toast.error(ERROR_SOMETHING_WENT_WRONG)
                }
                setLoading(false);
            }


    const handlePostQuestion = async (values) => {

        setBtnLoading(true);
        const resPostQuestion = await postAForumQuestion({ token: globalToken, question: values?.question, cat_id: values?.cat_id, scat_id: values?.scat_id, type: 'post', tag:values?.tag });
        if (resPostQuestion?.success) {
            toast.success('Question posted successfully.');
            navigate('/forum/my-forum')
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setBtnLoading(false)
    }



    const handleCustomSelectOnChange = (value, type, label, setFieldValue) => {

        if (type == 'category') {
            setSfValue(setFieldValue);
            setSubCategoryFormatted([]);
            setDefaultSCat(null);
            setFieldValue('scat_id', null);

            if (value) {
                handleFetchAllForumTopic(0, value)
                setCatId(value);
                setFieldValue('cat_id', value);
            } else {
                setCatId(null);
                setFieldValue('cat_id', null);
                // setCategoryFormatted([]);
            }
            setSCatId(null);

        }
        if (type == 'sCategory') {
            if (value) {
                findChoosenSubCat(value);
                setSCatId(value);
                setFieldValue('scat_id', value);
            } else {
                setSCatId(null);
                setDefaultSCat(null);
                setFieldValue('scat_id', null);
            }

        }
        if (type == 'tag') {
            if (value) {
                setTagId(value);
                setFieldValue('tag', value);
            } else {
                setTagId(null);
                setFieldValue('tag', null);
            }
        }
    }

    const findChoosenSubCat = (choosenSCat) => {
        const cSCat = subCategoryFormatted?.filter(sc => sc?.value == choosenSCat);
        setDefaultSCat(cSCat);
    }



    return (
        <>
            <BreadCrumbs />
            {loading ? (
                <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                    <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                        <div className="h-10 w-10">
                            <Loadingicon />
                        </div>
                    </div>
                </div>) : (
                <div className="py-0 px-0 w-100 card-body gap-4s">

                    <div className="p-4 w-100 my-2">
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                question: "",
                                cat_id: null,
                                scat_id: null,
                                tag: null
                            }}
                            validationSchema={sCatId ? DisplayingErrorMessagesSchemaSec : DisplayingErrorMessagesSchema}
                            onSubmit={(values) => {

                                if (user?.email_verified == 1) {
                                    if (values) {
                                        handlePostQuestion(values)
                                    }

                                } else {
                                    toast.error('Please verify your email');
                                    setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
                                }

                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                handleSubmit,
                            }) => (
                                <form className="" onSubmit={handleSubmit}>
                                    <div className="row">
                                        {
                                            formData?.map((fieldMeta, _i) => (
                                                <div className={`position-relative  ${fieldMeta.width ? 'col-md-12' : 'col-md-12'}`}>
                                                    <label
                                                        htmlFor={fieldMeta?.id}
                                                        className={`block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500`}
                                                    >
                                                        {fieldMeta?.title}

                                                        {
                                                            fieldMeta?.required ? <span className="text-red-400 text-base">*</span> : null
                                                        }
                                                    </label>
                                                    <input type="password" name="fake-password" autoComplete="new-password" style={{ display: 'none' }} />
                                                    {
                                                        fieldMeta.width ?
                                                            <>
                                                                <RichTextEditorComponent height={350} toolbarSettings={toolbarSettings}
                                                                    quickToolbarSettings={quickToolbarSettings}
                                                                    insertImageSettings={{saveFormat: "Base64" }}
                                                                    id={'editorContent' + _i}
                                                                    change={(e) => {
                                                                        setFieldValue(fieldMeta?.id, e.value); // Update Formik value
                                                                    }}
                                                                    placeholder="Enter content here">
                                                                    <div></div>
                                                                    <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar, Table]} />
                                                                </RichTextEditorComponent>
                                                                {/* <Editor
                                                                    apiKey={TINY_EDITOR_KEY}
                                                                    onInit={(evt, editor) => moderateEditorRef.current = editor}
                                                                    init={{
                                                                        height: '200',
                                                                        auto_focus: true,
                                                                        menubar: false,
                                                                        plugins: 'hr lists table textcolor code link image',
                                                                        toolbar: 'undo redo | blocks fontsize | bold italic forecolor link image media | alignleft aligncenter alignright | hr bullist numlist table | subscript superscript | removeformat code',

                                                                        // allow custom url in link? nah just disabled useless dropdown..
                                                                        file_picker_types: 'image',
                                                                        file_picker_callback: function (cb, value, meta) {
                                                                            var input = document.createElement('input');
                                                                            input.setAttribute('type', 'file');
                                                                            input.setAttribute('accept', 'image/*');
                                                                            input.onchange = () => {
                                                                                const file = input.files[0];
                                                                                const reader = new FileReader();

                                                                                reader.onload = () => {
                                                                                    const base64 = reader.result.split(',')[1];
                                                                                    cb('data:image/jpeg;base64,' + base64);
                                                                                };

                                                                                reader.readAsDataURL(file);
                                                                            };

                                                                            input.click();
                                                                        },
                                                                        anchor_top: false,
                                                                        anchor_bottom: false,
                                                                        branding: false,
                                                                        loading: true
                                                                    }}
                                                                    onEditorChange={(content, editor) => {
                                                                        setFieldValue(fieldMeta?.id, content);

                                                                    }}
                                                                /> */}
                                                                <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                    {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                </div>
                                                            </>
                                                            : fieldMeta.isCategory ?
                                                                <div className="w-100" style={{ zIndex: 102, position: 'relative' }}>

                                                                    <div className="d-block w-100">
                                                                        {
                                                                            <CustomSelect options={categoryFormatted} isMultiple={false} isClearable={true} handleCustomSelectOnChange={handleCustomSelectOnChange}
                                                                                setFieldValue={setFieldValue}
                                                                                type={'category'} isSelectLoading={isCategoryLoading} placeholder={'Category'} />
                                                                        }

                                                                    </div>

                                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                        {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                    </div>
                                                                </div> : fieldMeta.isSCategory ?
                                                                    <div className="w-100" style={{ zIndex: 101, position: 'relative' }}>


                                                                        <div className="d-block w-100">
                                                                            <CustomSelect
                                                                                options={subCategoryFormatted}
                                                                                defaultOption={defaultSCat}
                                                                                isMultiple={false} isClearable={true} handleCustomSelectOnChange={handleCustomSelectOnChange}
                                                                                setFieldValue={setFieldValue}
                                                                                type={'sCategory'} isSelectLoading={isSubCategoryLoading} placeholder={'Sub Category'} />
                                                                        </div>

                                                                        <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                            {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                        </div>
                                                                    </div>: fieldMeta.isTag ?
                                                                <div className="w-100" style={{ zIndex: 100, position: 'relative' }}>

                                                                    <div className="d-block w-100" >
                                                                        {
                                                                            <CustomSelectAsync options={tagFormatted} isMultiple={true} isClearable={true} handleCustomSelectOnChange={handleCustomSelectOnChange}
                                                                                setFieldValue={setFieldValue}
                                                                                type={'tag'} isSelectLoading={isTagLoading} placeholder={'Choose among tags'} />
                                                                        }

                                                                    </div>

                                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                        {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                    </div>
                                                                </div> : null}



                                                </div>
                                            ))
                                        }

                                    </div>
                                    <div className="col-md-2 mt-3">
                                        <button
                                            type="submit"
                                            disabled={btnLoading}
                                            className="mt-2 w-full text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center"
                                        >
                                            {btnLoading ? (
                                                <div className="h-5 w-5">
                                                    <Loadingicon />
                                                </div>
                                            ) : (
                                                "Submit"
                                            )}
                                        </button>
                                    </div>
                                </form>
                            )}

                        </Formik>
                    </div>
                </div>


            )
            }

        </>
    );
}
export default PostAQuestion;
